import {
  SecosimForm,
  EctoplannerForm,
  EctoplannerFormBuilding,
  NproPromiseInput,
  EctoplannerCalculationResult,
  SecosimBuilding
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import _ from 'lodash';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import defaultForm from './assets/defaultForm.json';

import defaultFormBuilding from 'ecto-common/lib/Ectoplanner/assets/defaultFormBuilding.json';
import EctoplannerAPIGen from 'ecto-common/lib/API/EctoplannerAPIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const getGasPrice = (
  economicParameters: EctoplannerForm['economicParameters']
) => {
  // Get required variables from input fields
  const spot_gas_price_t0 = economicParameters.price_ex_gas;
  let spot_gas_price_growth_rate = economicParameters.price_growth_gas / 100;
  const grid_surcharge_gas_t0 = economicParameters.price_grid_gas;
  const grid_surcharge_growth_gas = economicParameters.grid_growth_gas / 100;
  const tax_rate = economicParameters.tax_rate / 100;
  let interest_rate = economicParameters.interest_rate / 100;
  const lifetime = economicParameters.project_lifetime;

  // In case interest and growth rate are equal, slightly increase the growth rate to avoid division by zero
  if (spot_gas_price_growth_rate === interest_rate) {
    spot_gas_price_growth_rate += Number.EPSILON;
  }

  // Calculate Rentenbarwertfaktor
  const RBF =
    ((1 + interest_rate) ** lifetime - 1) /
    ((1 + interest_rate) ** lifetime * interest_rate);

  // Calculate NPV of the gas price
  const annualized_price_growth =
    (1 + spot_gas_price_growth_rate) *
    ((1 -
      ((1 + spot_gas_price_growth_rate) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - spot_gas_price_growth_rate));

  if (interest_rate === grid_surcharge_growth_gas) {
    interest_rate += Number.EPSILON;
  }

  const annualized_grid_surcharge_growth_gas =
    (1 + grid_surcharge_growth_gas) *
    ((1 - ((1 + grid_surcharge_growth_gas) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - grid_surcharge_growth_gas));

  const gas_price_npv =
    (1 + tax_rate) *
    (spot_gas_price_t0 * annualized_price_growth +
      annualized_grid_surcharge_growth_gas * grid_surcharge_gas_t0);

  // Annualize the gas price
  let gas_price_ann = gas_price_npv / RBF;

  // Round gas price to 2 decimal places
  gas_price_ann = Math.round((gas_price_ann + Number.EPSILON) * 100) / 100;

  return gas_price_ann;
};

const getElectricityPrice = (
  economicParameters: EctoplannerForm['economicParameters']
) => {
  // Get required variables from input fields
  const spot_el_price_t0 = economicParameters.price_ex_el;
  let spot_el_price_growth_rate = economicParameters.price_growth_el / 100;
  const grid_surcharge_el_t0 = economicParameters.price_grid_el;
  const grid_surcharge_growth_el = economicParameters.grid_growth_el / 100;
  const environmental_surcharge = economicParameters.env_surcharge;
  const other_surcharges = economicParameters.oth_surcharge;
  const tax_rate = economicParameters.tax_rate / 100;
  let interest_rate = economicParameters.interest_rate / 100;
  const lifetime = economicParameters.project_lifetime;

  // In case interest and growth rate are equal, slightly increase the growth rate to avoid division by zero
  if (spot_el_price_growth_rate === interest_rate) {
    spot_el_price_growth_rate += Number.EPSILON;
  }
  // Calculate Rentenbarwertfaktor
  const RBF =
    ((1 + interest_rate) ** lifetime - 1) /
    ((1 + interest_rate) ** lifetime * interest_rate);

  // Calculate NPV of the electricity price
  const annualized_price_growth =
    (1 + spot_el_price_growth_rate) *
    ((1 - ((1 + spot_el_price_growth_rate) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - spot_el_price_growth_rate));

  if (interest_rate === grid_surcharge_growth_el) {
    interest_rate += Number.EPSILON;
  }

  const annualized_grid_surcharge_growth_el =
    (1 + grid_surcharge_growth_el) *
    ((1 - ((1 + grid_surcharge_growth_el) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - grid_surcharge_growth_el));

  const el_price_npv =
    (1 + tax_rate) *
    (spot_el_price_t0 * annualized_price_growth +
      annualized_grid_surcharge_growth_el * grid_surcharge_el_t0 +
      RBF * environmental_surcharge +
      RBF * other_surcharges);

  // Annualize the electricity price
  let el_price_ann = el_price_npv / RBF;

  // Round the electricity price to 2 decimal places
  el_price_ann = Math.round((el_price_ann + Number.EPSILON) * 100) / 100;

  return el_price_ann;
};

type EctoplannerPatch = [
  key: (path: EctoplannerForm) => unknown,
  value: unknown
];

const parametersWithChanges = (
  form: EctoplannerForm,
  changes: EctoplannerPatch[]
): EctoplannerForm['economicParameters'] => {
  const tempForm = _.cloneDeep(form.economicParameters);

  for (const [key, value] of changes) {
    const keyPath = getPathFromModelKeyFunc(key);

    // Slice to remove 'economicParameters' from path
    _.set(tempForm, keyPath.slice(1), value);
  }

  return tempForm;
};

export const updateGasAndElectricityPriceWithChanges = (
  changes: EctoplannerPatch[],
  form: EctoplannerForm
): EctoplannerPatch[] => {
  const tempForm = parametersWithChanges(form, changes);

  return [
    ...changes,
    [(input) => input.energyCosts.price_gas, getGasPrice(tempForm)],
    [(input) => input.energyCosts.price_el, getElectricityPrice(tempForm)]
  ];
};

export const updateGasPriceWithChanges = (
  changes: EctoplannerPatch[],
  form: EctoplannerForm
): EctoplannerPatch[] => {
  return [
    ...changes,
    [
      (input) => input.energyCosts.price_gas,
      getGasPrice(parametersWithChanges(form, changes))
    ]
  ];
};

export const updateElectricityPriceWithChanges = (
  changes: EctoplannerPatch[],
  form: EctoplannerForm
): EctoplannerPatch[] => {
  return [
    ...changes,
    [
      (input) => input.energyCosts.price_el,
      getElectricityPrice(parametersWithChanges(form, changes))
    ]
  ];
};

let calculationIdCounter = 0;

export const citiesAndAirTempPromise = ({
  contextSettings,
  signal,
  weatherStationId
}: {
  contextSettings: ApiContextSettings;
  signal: AbortSignal;
  weatherStationId: string;
}) => {
  return Promise.all([
    EctoplannerAPIGen.Weather.airTempDetail.promise(
      contextSettings,
      { weatherStationId },
      signal
    ),
    EctoplannerAPIGen.Weather.cityDataDetail.promise(
      contextSettings,
      { weatherStationId },
      signal
    )
  ] as const);
};

export const compileFormPromise = ({
  inputForm,
  cityData,
  airTemp,
  calculationIndex
}: NproPromiseInput): Promise<EctoplannerCalculationResult> => {
  const worker = new Worker(
    new URL('./Calculation/EctoplannerCalculationWorker.ts', import.meta.url)
  );
  const idToUse = calculationIdCounter++;

  const promise = new Promise<EctoplannerCalculationResult>(
    (resolve, reject) => {
      worker.onmessage = ({ data: { form, checksum, id, error } }) => {
        if (id === idToUse) {
          if (error != null) {
            reject(error);
          } else {
            resolve({
              form: form as unknown as EctoplannerForm,
              checksum: checksum as string,
              calculationIndex
            });
          }
        }
      };
    }
  );

  worker.postMessage({
    form: inputForm,
    id: idToUse,
    cityData,
    airTemp
  });

  return promise;
};

// defaultSecosimForm.jsonasset contains values for every asset type in the building, so we can have
// default values when adding new assets (we just copy the field from there when adding a new asset).
// However, it would not be a good initial building to start with.
// We only want a basic set of assets then, therefore we use this function to override the default assets
// (which are all possible ones) with a basic set.
export function insertSecosimDefaultAssets(): SecosimBuilding['parameters']['assets'] {
  return {
    batteries: [],
    buffer_tanks: [
      {
        name: 'Buffer Tank Heat',
        volume: 2,
        max_temp: 55,
        min_temp: 30,
        heat_loss: 2.5,
        for_heating: true,
        for_dhw: false,
        for_cooling: false,
        indoors: true
      },
      {
        name: 'Buffer Tank DHW',
        volume: 2,
        max_temp: 73,
        min_temp: 66,
        heat_loss: 2.5,
        for_dhw: true,
        for_heating: false,
        for_cooling: false,
        indoors: true
      },
      {
        name: 'Buffer Tank Cooling',
        volume: 2,
        max_temp: 5,
        min_temp: -15,
        heat_loss: 2.5,
        for_dhw: false,
        for_heating: false,
        for_cooling: true,
        indoors: true
      }
    ],
    cooling_direct: [],
    diesel_generators: [],
    gas_generators: [],
    heat_pumps: [
      {
        name: 'Air Heat Pump',
        capacity: 2000,
        eta: 0.38,
        sink: 'for_heating',
        source: 'air'
      },
      {
        name: 'Air Heat Pump DHW',
        capacity: 2000,
        eta: 0.454,
        sink: 'for_dhw',
        source: 'air'
      },
      {
        name: 'Air Heat Pump Cooling',
        capacity: 2000,
        eta: 0.454,
        sink: 'air',
        source: 'for_cooling'
      }
    ],
    e_boilers: [],
    evs: [],
    gas_boilers: [],
    pv: [],
    thermal_flex: []
  };
}

export function insertSecosimDefaultValues(
  formJson: SecosimForm,
  defaultSecosimForm: SecosimForm
) {
  if (formJson == null) {
    return [null, false] as const;
  }

  if (_.isEmpty(formJson)) {
    const emptyForm = _.cloneDeep(defaultSecosimForm);

    emptyForm.buildings[0].parameters.assets = insertSecosimDefaultAssets();

    return [emptyForm, true] as const;
  }

  // Make sure new default sections are available for older projects
  const clone = _.cloneDeep({
    ..._.merge({}, defaultSecosimForm, formJson),
    buildings: formJson.buildings ?? []
  });

  const defaultFormBuildingTyped = defaultSecosimForm.buildings[0];

  for (const building of clone.buildings) {
    const assets = building.parameters?.assets;

    if (building.parameters == null) {
      building.parameters = _.cloneDeep(defaultFormBuildingTyped.parameters);
    } else {
      building.parameters = _.merge(
        {},
        _.cloneDeep(defaultFormBuildingTyped.parameters),
        building.parameters
      );
    }
    if (assets != null) {
      for (const key of Object.keys(assets)) {
        const defaultDataForAsset =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (defaultFormBuildingTyped.parameters.assets as any)[key]?.[0];

        if (defaultDataForAsset == null) {
          console.error('ERROR: No default data for asset', key);
          continue;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (assets as any)[key] = (assets as any)[key].map((asset: any) => {
          return _.merge({}, _.cloneDeep(defaultDataForAsset), asset);
        });
      }

      for (const key of Object.keys(
        defaultFormBuildingTyped.parameters.assets
      )) {
        if (key in assets === false) {
          _.set(assets, key, []);
        }
      }

      building.parameters.assets = assets;
    }

    building.el_demand =
      building.el_demand ?? defaultFormBuildingTyped.el_demand;
    building.demand_temperature =
      building.demand_temperature ??
      defaultFormBuildingTyped.demand_temperature;
    building.demand_cooling =
      building.demand_cooling ?? defaultFormBuildingTyped.demand_cooling;
    building.demand_dhw =
      building.demand_dhw ?? defaultFormBuildingTyped.demand_dhw;
    building.demand_heating =
      building.demand_heating ?? defaultFormBuildingTyped.demand_heating;
  }

  const hasChanges = !_.isEqual(clone, formJson);

  return [clone, hasChanges] as const;
}

export function insertEctoplannerDefaultValues(formJson: EctoplannerForm) {
  if (formJson == null) {
    return [null, false] as const;
  }

  if (_.isEmpty(formJson)) {
    // TODO: Type casting due to JSON import. Perhaps there is a better way?
    return [
      _.cloneDeep(defaultForm as unknown as EctoplannerForm),
      true
    ] as const;
  }

  const defaultFormBuildingTyped =
    defaultFormBuilding as EctoplannerFormBuilding;

  // Make sure new default sections are available for older projects
  const clone = _.cloneDeep({
    ..._.merge({}, defaultForm, formJson),
    buildings: formJson.buildings
  });

  // Force insulation to be set to 'insulated' for all networks for now,
  // only option we support.
  clone.network.netwInsulation = 'insulated';

  for (const building of clone.buildings) {
    if (building.params == null) {
      building.params = _.cloneDeep(defaultFormBuildingTyped.params);
    } else {
      building.params = _.merge(
        {},
        _.cloneDeep(defaultFormBuildingTyped.params),
        building.params
      );
    }
  }

  const hasChanges = !_.isEqual(clone, formJson);

  return [clone, hasChanges] as const;
}
