import {
  EctoplannerFormBuilding,
  EctoplannerFormBuildingParams
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import _ from 'lodash';

export const ectoplannerCityDataKeys: (keyof EctoplannerFormBuildingParams)[] =
  [
    'annDemSpecHeat',
    'annDemSpecCool',
    'annDemSpecDhw',
    'annDemSpecProcessCool',
    'annDemSpecPlugLoads'
  ];

export const updateTotalDemandForBuilding = (
  building: EctoplannerFormBuildingParams
) => {
  building.annDemTotHeat = Math.round(
    (building.floorArea * building.annDemSpecHeat) / 1000.0
  );
  building.annDemTotCool = Math.round(
    (building.floorArea * building.annDemSpecCool) / 1000.0
  );
  building.annDemTotProcessCool = Math.round(
    (building.floorArea * building.annDemSpecProcessCool) / 1000.0
  );
  building.annDemTotPlugLoads = Math.round(
    (building.floorArea * building.annDemSpecPlugLoads) / 1000.0
  );
  building.annDemTotDhw = Math.round(
    (building.floorArea * building.annDemSpecDhw) / 1000.0
  );
};

export function migrateEctoplannerBuildings(
  buildings: EctoplannerFormBuilding[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cityData: any
): EctoplannerFormBuilding[] {
  return _.map(buildings, (building) => {
    // If we do not have city data for the building type - can happen when we change to a new country with
    // different building types - we should reset the building type and subtype
    if (
      cityData[building.params?.buildingType ?? ''] == null ||
      cityData[building.params.buildingType][
        building.params.buildingSubtype ?? ''
      ] == null
    ) {
      return {
        ...building,
        params: {
          ...building.params,
          buildingType: null,
          buildingSubtype: null
        }
      };
    }

    // Update the building with demand figures from the new city data
    const ret: EctoplannerFormBuilding = {
      ...building,
      params: { ...building.params }
    };

    for (const key of ectoplannerCityDataKeys) {
      _.set(
        ret.params,
        key,
        cityData[building.params.buildingType][building.params.buildingSubtype][
          key
        ]
      );
    }

    updateTotalDemandForBuilding(ret.params);
    return ret;
  });
}
