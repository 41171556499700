import { ResourceType } from 'ecto-common/lib/constants';
import { generatePath } from 'react-router-dom';
import { Route } from 'ecto-common/lib/types/Route';
import { SignalProvidersPageValue } from 'ecto-common/lib/utils/commonLinkUtil';

export const UserDashboardsSubPage = 'userdashboards';

export const LocationRoute: Route = {
  path: '/:tenantId/home/:nodeId/:page/:subPage?/:itemId?',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const NoLocationRoute: Route = {
  path: '/:tenantId/noLocations',
  exact: true,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const ExportGraphRoute: Route = {
  path: '/:tenantId/graphs/exports/:exportId',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const ExportDataRoute: Route = {
  path: '/:tenantId/exportdata',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};

export const GraphsRoute: Route = {
  path: '/:tenantId/graphs',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};

export const HelpRoute: Route = { path: '/:tenantId/help' };
export const EctoplannerRoute: Route = {
  path: '/:tenantId/simulations/:projectType?/:projectId?/:buildId?/:section?/:itemId?',
  resourceTypes: [ResourceType.ECTOPLANNER],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const EctoplannerResultsRoute: Route = {
  path: '/:tenantId/ectoplannerResults/:projectId/:itemId',
  resourceTypes: [ResourceType.ECTOPLANNER],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};

export type ExportDataParams = {
  tenantId: string;
  exportId?: string;
};

export type EctoplannerParams =
  | 'tenantId'
  | 'projectType'
  | 'projectId'
  | 'buildId'
  | 'section'
  | 'itemId';

export const ectoplannerMasterBuildId = 'current';

export const getEctoplannerUrl = (
  tenantId: string,
  projectType: string,
  projectId?: string,
  buildId: string = ectoplannerMasterBuildId,
  section: string = undefined,
  itemId: string = undefined
) => {
  let suffix = '';
  if (projectId == null) {
    suffix = '/';
  }

  if (projectId == null) {
    buildId = null;
  }

  return (
    generatePath(EctoplannerRoute.path, {
      projectType,
      tenantId,
      buildId,
      projectId,
      section,
      itemId
    }) + suffix
  );
};

export const getEctoplannerResultsUrl = (
  tenantId: string,
  projectId: string,
  itemId: string
) => {
  return generatePath(EctoplannerResultsRoute.path, {
    tenantId,
    projectId,
    itemId
  });
};

export const getNodesV2Url = (
  tenantId: string,
  nodeId: string,
  subPage: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage,
    page: 'nodesv2'
  });
};

export const getJobsUrl = (
  tenantId: string,
  nodeId: string,
  jobId?: string,
  runId?: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage: jobId,
    itemId: runId,
    page: 'jobs'
  });
};

export const getSignalsUrl = (tenantId: string, nodeId: string) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    page: SignalProvidersPageValue
  });
};

export const getAlarmUrlV2 = (
  tenantId: string,
  nodeId: string,
  subPage: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage,
    page: 'alarms'
  });
};

function generateUrl<T extends Record<string, string | number>>(
  pathTemplate: string,
  pathParams: T,
  queryParams?: Record<string, string | number>
) {
  const path = generatePath(pathTemplate, pathParams);
  const search = queryParams
    ? `?${new URLSearchParams(
        Object.fromEntries(
          Object.entries(queryParams).map(([k, v]) => [k, String(v)])
        )
      ).toString()}`
    : '';

  return `${path}${search}`;
}

export const getDashboardUrl = ({
  tenantId,
  nodeId,
  dashboardId,
  queryParams
}: {
  tenantId: string;
  nodeId: string;
  dashboardId?: string;
  queryParams?: Record<string, string | number>;
}) => {
  return generateUrl(
    LocationRoute.path,
    {
      tenantId,
      nodeId,
      subPage: dashboardId,
      page: 'dashboard'
    },
    queryParams
  );
};

export const getUserDashboardsUrl = (
  tenantId: string,
  nodeId: string,
  userDashboardId: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage: userDashboardId,
    page: 'userdashboards'
  });
};
