import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import T from 'ecto-common/lib/lang/Language';
import EctoplannerResults from 'js/components/Ectoplanner/EctoplannerResults';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

import { useNavigate, useParams } from 'react-router-dom';
import { EctoplannerParams, getEctoplannerUrl } from 'js/utils/routeConstants';

import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import {
  BuildResponse,
  ProjectResponse,
  WeatherCountryResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  citiesAndAirTempPromise,
  compileFormPromise,
  insertEctoplannerDefaultValues
} from 'js/components/Ectoplanner/EctoplannerModelUtils';
import {
  OptionWithIconAndView,
  useEctoplannerFormCalculation,
  useEctoplannerFormOptions,
  useEctoplannerFormValidation,
  useEctoplannerValidation
} from './useEctoplannerFormOptions';
import EctoplannerFormErrorsModal from 'js/components/Ectoplanner/EctoplannerFormErrorsModal';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import {
  EctoplannerProjectTypes,
  calculateEctoplannerChecksum
} from 'js/components/Ectoplanner/EctoplannerTypes';
import Button from 'ecto-common/lib/Button/Button';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import {
  EctoplannerConfirmSaveBeforeNewDialog,
  useEctoplannerToolbarMenuOptions
} from 'js/components/Ectoplanner/EctoplannerNavigationControls';
import {
  ectoplannerCalculationIsLoading,
  saveAndBuildPromise,
  saveEctoplannerFormPromise
} from 'js/components/Ectoplanner/EctoplannerUtils';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { migrateEctoplannerBuildings } from 'ecto-common/lib/Ectoplanner/EctoplannerBuildingFormModelUtils';

const SectionCalculations = 'calculations';
const SectionGraphs = 'graphs';

const Y = {
  ectoplanner: {
    confirmpastetitle: 'Confirm pasted form',
    pastedform:
      'A form has been pasted from the clipboard. Do you want to use it?'
  }
};

interface EditEctoplannerProjectProps {
  allProjects: ProjectResponse[];
  weatherCountries: WeatherCountryResponse[];
  form: EctoplannerForm;
  isLoadingProjects: boolean;
  isLoadingForm: boolean;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>>;
  clearHasChanges: () => void;
  hasChanges: boolean;
  onRecalculate: (buildResponse: BuildResponse) => void;
  navigationTree: React.ReactNode;
  navigationControl: React.ReactNode;
  selectedBuild?: BuildResponse;
  onClickAddNew: () => void;
  onClickShare: () => void;
  onClickDelete: () => void;
  onClickEditName: () => void;
  onClickExport: () => void;
  isExporting: boolean;
}

const EditEctoplannerProject = ({
  allProjects,
  weatherCountries,
  form,
  isExporting,
  isLoadingForm,
  isLoadingProjects,
  setForm,
  setFormFromUserInput,
  clearHasChanges,
  hasChanges,
  onRecalculate,
  navigationControl,
  navigationTree,
  selectedBuild,
  onClickAddNew,
  onClickShare,
  onClickDelete,
  onClickEditName,
  onClickExport
}: EditEctoplannerProjectProps) => {
  const params = useParams<EctoplannerParams>();
  const navigate = useNavigate();

  const [pastedForm, setPastedForm] = useState<EctoplannerForm>(null);

  const [confirmingSave, showConfirmSave, hideConfirmSave] =
    useSimpleDialogState();

  const [showingErrorDialog, showErrorDialog, hideErrorDialog] =
    useSimpleDialogState();

  const buildId = selectedBuild?.id;

  const { tenantId, contextSettings } = useContext(TenantContext);

  const currentWeatherStationId = form?.location?.city?.id;

  useEffect(() => {
    const pasteListener = (clipboardEvent: ClipboardEvent) => {
      if (clipboardEvent.target instanceof HTMLInputElement) {
        return;
      }

      const text = clipboardEvent.clipboardData.getData('Text');

      try {
        const pasteStateData = JSON.parse(text);
        if (pasteStateData.buildings != null) {
          const [newForm] = insertEctoplannerDefaultValues(
            pasteStateData as EctoplannerForm
          );
          setPastedForm(newForm);
        }
      } catch (e) {
        console.error(e);
      }
    };

    document.addEventListener('paste', pasteListener);

    return () => {
      document.removeEventListener('paste', pasteListener);
    };
  }, []);

  const citiesAndAirTempQuery = useQuery({
    queryKey: ['citiesAndAirTemp', currentWeatherStationId],

    queryFn: ({ signal }) => {
      return citiesAndAirTempPromise({
        weatherStationId: currentWeatherStationId,
        signal,
        contextSettings
      });
    },

    enabled: currentWeatherStationId != null,
    refetchOnWindowFocus: false
  });

  // const buildItem = _.find(builds, ['id', params.buildId]);

  const [airTemp, cityData] = citiesAndAirTempQuery.data ?? [null, null, null];

  const [hasErrors, formErrors, formErrorSections] =
    useEctoplannerFormValidation({
      form,
      cityData,
      projectId: params.projectId
    });

  const [triggerCalculation, checksum] = useEctoplannerFormCalculation({
    form,
    setForm,
    hasErrors,
    cityData,
    airTemp,
    buildId
  });
  const setFormAndCalculate = useCallback(
    (newForm: React.SetStateAction<EctoplannerForm>) => {
      // Form has changed from user input: Trigger rebuild.
      setForm(newForm);
      triggerCalculation();
    },
    [setForm, triggerCalculation]
  );

  const setFormFromUserInputLocal = useCallback(
    (newForm: React.SetStateAction<EctoplannerForm>) => {
      // Form has changed from user input: Trigger rebuild.
      setFormFromUserInput(newForm);
      triggerCalculation();
    },
    [setFormFromUserInput, triggerCalculation]
  );

  const confirmPastedForm = useCallback(() => {
    setFormAndCalculate(pastedForm);
    setPastedForm(null);
  }, [pastedForm, setFormAndCalculate]);

  const cancelPastedForm = useCallback(() => {
    setPastedForm(null);
  }, []);

  const lastFetchedWeatherStationId = useRef<string>(null);

  useEffect(() => {
    lastFetchedWeatherStationId.current = null;
  }, [params.projectId, params.buildId, params.projectType]);

  const weatherStationId = form?.location?.city?.id;

  useEffect(() => {
    // Delay calculating the form until we have gotten the new air temp and city data that corresponds to the
    // weather station. After that, we can trigger the new calculation.
    if (
      weatherStationId != null &&
      cityData != null &&
      airTemp != null &&
      lastFetchedWeatherStationId.current !== weatherStationId
    ) {
      if (lastFetchedWeatherStationId.current != null) {
        // We have changed weather stations, so we need to migrate all of the buildings to
        // the new weather station. They will receive new heating profiles based on that city.
        // Then, the form will be calculated with the new city data.
        setFormAndCalculate((oldForm: EctoplannerForm) => ({
          ...oldForm,
          buildings: migrateEctoplannerBuildings(oldForm.buildings, cityData)
        }));
      } else {
        // No need to migrate buildings, just perform initial calculation (since form is stripped of
        // calculation data to conserve space we do not include calculation data when saving to server).
        setFormAndCalculate((oldForm: EctoplannerForm) => ({
          ...oldForm
        }));
      }
      lastFetchedWeatherStationId.current = weatherStationId;
    }
  }, [airTemp, cityData, setFormAndCalculate, weatherStationId]);

  const calculateProfilesAndSaveMutation = useMutation({
    mutationFn: compileFormPromise,
    onSuccess: (data) => {
      setForm(data.form);
      saveMutation.mutate({
        contextSettings,
        projectId: params.projectId,
        buildId: params.buildId,
        formData: {
          projectType: EctoplannerProjectTypes.Ectoplanner,
          data: data.form
        },
        lastWeatherStationId: lastWeatherStationId.current
      });
    }
  });

  const lastWeatherStationId = useRef(null);

  const buildMutation = useMutation({
    mutationFn: saveAndBuildPromise,
    onSuccess: (buildResponse) => {
      clearHasChanges();
      onRecalculate(buildResponse);
    },
    onError: () => {
      toastStore.addErrorToast(T.ectoplanner.calculateerror);
    }
  });

  const saveMutation = useMutation({
    mutationFn: saveEctoplannerFormPromise,
    onSuccess: () => {
      lastWeatherStationId.current = form.location.city.id;
      clearHasChanges();
      if (confirmingSave) {
        hideConfirmSave();
        onClickAddNew();
      }
    },
    onError: (e) => {
      toastStore.addErrorToast(T.ectoplanner.saveerror);
      console.error(e);
    }
  });

  const save = useCallback(() => {
    if (hasErrors) {
      saveMutation.mutate({
        contextSettings,
        projectId: params.projectId,
        buildId: params.buildId,
        formData: {
          projectType: EctoplannerProjectTypes.Ectoplanner,
          data: form
        },
        lastWeatherStationId: lastWeatherStationId.current
      });
    } else {
      calculateProfilesAndSaveMutation.mutate({
        calculationIndex: 0, // Calculation index not used here since the promise will always use the result
        inputForm: form,
        cityData,
        airTemp
      });
    }
  }, [
    hasErrors,
    saveMutation,
    contextSettings,
    params.projectId,
    params.buildId,
    form,
    calculateProfilesAndSaveMutation,
    cityData,
    airTemp
  ]);

  const formOptions = useEctoplannerFormOptions({
    projectId: params.projectId,
    form: form,
    setForm,
    setFormFromUserInput: setFormFromUserInputLocal,
    formErrors,
    weatherCountries,
    cityData
  });

  const calculationIsLoading = ectoplannerCalculationIsLoading(selectedBuild);

  const loadingCitiesAndAirTemp =
    currentWeatherStationId != null && citiesAndAirTempQuery.isLoading;
  const isLoading =
    isLoadingForm ||
    saveMutation.isPending ||
    isExporting ||
    loadingCitiesAndAirTemp;

  const [validationComponent, hasNoTechs] = useEctoplannerValidation({
    form,
    hasErrors,
    currentWeatherStationId
  });

  const calculationDisabled =
    buildMutation.isPending ||
    calculationIsLoading ||
    isLoading ||
    form?.buildings?.length === 0 ||
    hasNoTechs;

  const confirmCalculate = useCallback(() => {
    if (
      params.section !== SectionCalculations &&
      params.section !== SectionGraphs
    ) {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          params.projectId,
          params.buildId,
          SectionCalculations
        )
      );
    }

    // The other checksum is calculated using the calculation web worker, and might
    // not have been recalculated at this point if the user is quick to hit
    // calculate. So redo it just in case so we don't get invalid checksums
    // for the build.
    const newChecksum = calculateEctoplannerChecksum(form);

    buildMutation.mutate({
      contextSettings,
      projectId: params.projectId,
      buildId: params.buildId,
      formData: {
        projectType: EctoplannerProjectTypes.Ectoplanner,
        data: form
      },
      hasChanges,
      lastWeatherStationId: lastWeatherStationId.current,
      checksum: newChecksum
    });
  }, [
    params.section,
    params.projectId,
    params.buildId,
    params.projectType,
    form,
    buildMutation,
    contextSettings,
    hasChanges,
    navigate,
    tenantId
  ]);

  const calculate = useCallback(() => {
    if (Object.keys(formErrorSections).length === 0) {
      confirmCalculate();
    } else {
      showErrorDialog();
    }
  }, [confirmCalculate, formErrorSections, showErrorDialog]);

  const formIsUpToDate =
    selectedBuild == null ||
    isNullOrWhitespace(checksum) ||
    checksum === selectedBuild?.checksum;
  const isRunningCalculation = calculationIsLoading || buildMutation.isPending;

  const sections: OptionWithIconAndView[] = useMemo(() => {
    const result = [
      ...formOptions,
      {
        icon: <Icons.Table />,
        value: SectionCalculations,
        label: <>{T.ectoplanner.results.title}</>,
        view: (
          <EctoplannerResults
            isRunningCalculation={isRunningCalculation}
            selectedBuild={selectedBuild}
            projectId={params.projectId}
            formIsUpToDate={formIsUpToDate}
          />
        )
      }
    ];

    return result;
  }, [
    formIsUpToDate,
    formOptions,
    isRunningCalculation,
    params.projectId,
    selectedBuild
  ]);

  let curSection = _.findIndex(sections, ['value', params.section]);
  curSection = curSection === -1 ? 0 : curSection;

  const onChangeValue = useCallback(
    (newValue: OptionWithIcon) => {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          params.projectId,
          params.buildId,
          newValue.value
        )
      );
    },
    [navigate, tenantId, params.projectType, params.projectId, params.buildId]
  );

  const verifyOnClickAddNew = useCallback(() => {
    if (hasChanges) {
      showConfirmSave();
    } else {
      onClickAddNew();
    }
  }, [hasChanges, onClickAddNew, showConfirmSave]);

  const toolbarMenuOptions = useEctoplannerToolbarMenuOptions({
    saveButton: (
      <ToolbarMenuButton
        icon={<Icons.Save />}
        disabled={
          !hasChanges ||
          saveMutation.isPending ||
          params.projectId == null ||
          calculateProfilesAndSaveMutation.isPending
        }
        onClick={save}
        tooltipText={T.common.save}
      />
    ),
    onClickAddNew: verifyOnClickAddNew,
    onClickShare,
    onClickDelete,
    onClickEditName,
    selectedBuild,
    onClickExport,
    airTemp,
    cityData,
    isLoading,
    currentWeatherStationId,
    form,
    calculationRequiresCityData: true
  });

  const toolbarItems = useMemo(() => {
    return (
      <>
        <ToolbarItem>
          <ToolbarMenu>{toolbarMenuOptions}</ToolbarMenu>
        </ToolbarItem>
        {params.projectId != null && (
          <ToolbarItem expanding>
            <CollapsingSegmentControlPicker
              options={sections}
              value={sections[curSection]}
              onChangeValue={onChangeValue}
            />
          </ToolbarItem>
        )}

        <ToolbarItem>
          <Button
            loading={calculationIsLoading || buildMutation.isPending}
            disabled={calculationDisabled || params.projectId == null}
            onClick={calculate}
          >
            <Icons.Calculator />
            {T.ectoplanner.calculate}
          </Button>
        </ToolbarItem>
      </>
    );
  }, [
    toolbarMenuOptions,
    params.projectId,
    sections,
    curSection,
    onChangeValue,
    calculationIsLoading,
    buildMutation.isPending,
    calculationDisabled,
    calculate
  ]);

  return (
    <ToolbarContentPage
      wrapContent={
        params.projectId != null && params.section !== 'calculations'
      }
      title={T.ectoplanner.title}
      navigationTree={navigationTree}
      navigationControl={navigationControl}
      showLocationPicker={false}
      toolbarItems={toolbarItems}
    >
      {allProjects.length === 0 && !isLoadingProjects && (
        <NoDataMessage message={T.ectoplanner.noprojectshelp} />
      )}
      {((isLoadingForm && params.section !== 'calculations') ||
        isLoadingProjects) && <Spinner />}
      {(!isLoading || form != null) &&
        params.projectId != null &&
        validationComponent}

      {sections[curSection].view}
      <EctoplannerFormErrorsModal
        isOpen={showingErrorDialog}
        onModalClose={hideErrorDialog}
        formErrorSections={formErrorSections}
      />
      <EctoplannerConfirmSaveBeforeNewDialog
        isOpen={confirmingSave}
        onSave={save}
        onHideConfirmSave={hideConfirmSave}
        onClickAddNew={onClickAddNew}
        isLoading={
          saveMutation.isPending || calculateProfilesAndSaveMutation.isPending
        }
      />

      <ActionModal
        isOpen={pastedForm != null}
        title={Y.ectoplanner.confirmpastetitle}
        onConfirmClick={confirmPastedForm}
        onModalClose={cancelPastedForm}
      >
        {Y.ectoplanner.pastedform}
      </ActionModal>
    </ToolbarContentPage>
  );
};

export default EditEctoplannerProject;
