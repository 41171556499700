import React, { useCallback, useEffect, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import styles from './ToolbarDateTimePicker.module.css';

import moment, { Moment } from 'moment';

// Needed so that the DateTime component can use timezone correctly

import ToolbarMenuItem from 'ecto-common/lib/Toolbar/ToolbarMenuItem';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import DateInput from 'ecto-common/lib/DateInput/DateInput';
import { renderWeekday } from '../TimeRangeSelector/TimeRangeCalendar';
import { TimeRangeOptions } from '../types/TimeRangeOptions';
import { ViewMode } from 'react-datetime';

interface ToolbarDateTimeRangePickerProps {
  onChangeFrom?(newDate: Moment): void;
  onChangeTo?(newDate: Moment): void;
  dateFrom?: number;
  dateTo?: number;
  expandingItemWidth?: boolean;
  timeRangeOption?: string;
}

const ToolbarDateTimeRangePicker = ({
  onChangeFrom,
  onChangeTo,
  dateFrom,
  dateTo,
  expandingItemWidth = false,
  timeRangeOption
}: ToolbarDateTimeRangePickerProps) => {
  const [fromDate, setFromDate] = useState<Moment>(null);
  const [toDay, setToDay] = useState<Moment>(null);

  useEffect(() => {
    if (dateFrom !== -1 && dateTo !== -1) {
      setFromDate(moment.utc(dateFrom));
      setToDay(moment.utc(dateTo));
    }
  }, [dateFrom, dateTo]);

  const isValidDate = useCallback(
    (date: Moment) => {
      // In time range mode we should be able to select next week or month
      if (
        timeRangeOption === TimeRangeOptions.WEEK ||
        timeRangeOption === TimeRangeOptions.MONTH
      ) {
        return true;
      }
      return date.isBefore(toDay);
    },
    [timeRangeOption, toDay]
  );

  let dateFormat: string;
  let initialViewMode: ViewMode;
  if (timeRangeOption === TimeRangeOptions.MONTH) {
    dateFormat = 'YYYY-MM';
    initialViewMode = 'months';
  } else if (timeRangeOption === TimeRangeOptions.YEAR) {
    dateFormat = 'YYYY';
    initialViewMode = 'years';
  } else {
    initialViewMode = 'days';
  }
  return (
    <>
      <ToolbarMenuItem className={styles.toolbarMenuItemDateInputWrapper}>
        <DateInput
          key={timeRangeOption}
          value={fromDate}
          onChange={onChangeFrom}
          isValidDate={isValidDate}
          label={T.common.dateinput.from}
          className={styles.toolbarMenuItemLeftInput}
          dateFormat={dateFormat}
          lockDateFormat={
            timeRangeOption === TimeRangeOptions.MONTH ||
            timeRangeOption === TimeRangeOptions.YEAR
          }
          expandingWidth={expandingItemWidth}
          renderDay={
            timeRangeOption === TimeRangeOptions.WEEK
              ? renderWeekday
              : undefined
          }
          initialViewMode={initialViewMode}
        />
      </ToolbarMenuItem>

      <ToolbarMenuDivider />

      <ToolbarMenuItem className={styles.toolbarMenuItemDateInputWrapper}>
        <DateInput
          value={toDay}
          onChange={onChangeTo}
          isValidDate={(d) => d.isAfter(fromDate)}
          label={T.common.dateinput.to}
          className={styles.toolbarMenuItemRightInput}
          expandingWidth={expandingItemWidth}
          enabled={
            timeRangeOption === TimeRangeOptions.DAY ||
            timeRangeOption === TimeRangeOptions.HOUR
          }
          renderDay={
            timeRangeOption === TimeRangeOptions.WEEK
              ? renderWeekday
              : undefined
          }
        />
      </ToolbarMenuItem>
    </>
  );
};

export default ToolbarDateTimeRangePicker;
